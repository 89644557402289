import React from 'react'
import userBad from './userBad.svg'
import userGood from './userGood.svg'

type a = React.HTMLProps<HTMLImageElement>

interface ConfirmationIconProps extends React.HTMLAttributes<HTMLImageElement> {
    approved: boolean
}

const ConfirmationIcon = ({approved, ...props }: ConfirmationIconProps) => {
    return <img src={approved ? userGood : userBad} {...props}/>
}

export default ConfirmationIcon