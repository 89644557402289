import React from "react";
import TextInput from "../../Layout/TextInput";

const IdInput = (props: {default?: string, onChange?: ((e: React.ChangeEvent<HTMLInputElement>) => void)}) => {
    return (
        <TextInput
            inputMask="9999 99999 9999"
            placeholder="Insert Card Number"
            width="90vw"
            maxWidth="500px"
            height="30px"
            accentColor="white"
            fontSize="20px"
            default={props.default}
            onChange={props.onChange}
        />
    );
};

export default IdInput
