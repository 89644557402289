import React from 'react'
import styled from 'styled-components'
import Flex from '../../../Layout/Flex'
import ConfirmationIcon from './ConfirmationIcon'
import Button from '../../../Layout/Button'
import { formatCardNumber } from '../../../Util/stringUtil'
import IdInput from '../../../Components/IdInput'
import { redirectToId } from '../../../Util/urlUtil'
import Logo from '../../../Components/Logo'

interface UserInfoProps {
    id: string,
    name: string,
    approved: boolean
}

const colorBad = "#DE4949"
const colorGood = "#46DE7B"

const UserInfo = (props: UserInfoProps)=>{
    const {id, name, approved} = props
    const color = approved ? colorGood : colorBad
    return (

        <Flex flexDirection="row" justifyContent="center" alignItems="center" flexWrap="wrap">
            <ConfirmationIcon approved={approved} style={{margin: "40px"}}/>
            <Flex flexDirection="column" justifyContent="center" alignItems="flex-start" style={{color}}>
                <span>{formatCardNumber(id)}</span>
                <hr style={{width: "100%", border: `1px solid ${color}`}}/>
                <h1>{name ?? "Unknown ID"} – {approved ? "Valid" : "Invalid"}</h1>
            </Flex>
        </Flex>
    )
}

export default UserInfo
