import React from "react";
import Actions from './Store/Actions'
import Reducers from './Store/Reducers'

interface storeI {
    currentUser: {
        id?: string;
        name?: string;
        valid?: boolean;
        loading: boolean;
    };
}

type storeKey = keyof storeI;
type storeElement = storeI[storeKey];
type storeOrElement = storeI | storeElement;
export type reducer = (state: storeI, action: action) => storeI;

interface action {
    type: Actions;
    payload: any;
}

const store: storeI = {
    currentUser: {
        loading: false,
    },
};
const storeObj = React.createContext<storeI>(store);
const dispatchObj = React.createContext<React.Dispatch<action> | undefined>(
    undefined
);

let reducers: reducer[] = [];

export const useSelector = (sel: (state: storeI) => void) =>
    sel(React.useContext(storeObj));
export const useState = () => React.useContext(storeObj);

export const useDispatch = () => {
    return React.useContext(dispatchObj)!;
};

const reduce = (state: storeI, action: action) => {
    return reducers.reduce((obj, reducer) => reducer(obj, action), state);
};

export const Provider = (props: { children: any }) => {
    const [state, dispatch] = React.useReducer(reduce, store);
    return (
        <storeObj.Provider value={state}>
            <dispatchObj.Provider value={dispatch}>
                {props.children}
            </dispatchObj.Provider>
        </storeObj.Provider>
    );
};

export const registerReducer = (reducer: reducer) => reducers.push(reducer);

export const deregisterReducer = (reducer: reducer) =>
(reducers = reducers.splice(
    reducers.findIndex((v) => v === reducer),
    1
));

export type Epic = (dispatch: React.Dispatch<action>, ...params: any[]) => void


reducers.push(...Reducers)
// export const useCreateEpic = (
//     epic: (dispatch: React.Dispatch<action>,state: storeI, action: action) => storeI | void
// ): Epic => (s: storeI, a: action) => {
//     const d = useDispatch();
//     if (d) {
//         const ret = epic(d, s, a);
//         if (ret) return ret;
//     }
//     return s;
// };

// const reducers:reducer[] = [
//     (state, action) => {
//         switch(action.type) {
//             case actionType.currentUserGet:
//                 return state
//             case actionType.currentUserSet:
//                 return { ...state, currentUser: action.payload }
//         }
//     }
// ]

export default {
    Provider,
    registerReducer,
    deregisterReducer,
    useDispatch,
    useState,
    useSelector,
};
