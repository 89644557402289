import React from "react";
import styled from "styled-components";
import InputMask, {Props as inputMaskProps} from 'react-input-mask'

interface TextInputProps {
    default?: string;
    placeholder?: string;
    accentColor?: string;
    textColor?: string;
    fontSize?: string | number;
    disabled?: boolean;
    height?: string | number;
    width?: string | number;
    maxWidth?: string | number;
    maxHeight?: string | number;
    readonly?: boolean;
    onEdit?: (val: string) => void;
    onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
    inputMask?: inputMaskProps["mask"],
    maskPlaceholder?: inputMaskProps["maskPlaceholder"]
}

const StyledInput = styled(InputMask)`
    width: ${(p) => p.width};
    height: ${(p) => p.height};
    max-width: ${(p: TextInputProps) => p.maxWidth};
    max-height: ${(p: TextInputProps) => p.maxHeight};
    background: none;
    outline: none;
    border-top: 0;
    border-right: 0;
    border-bottom: 2px solid "white";
    transition: border-color 200ms ease-in-out;
    transition: border 200ms ease-in-out;
    color: ${(p: TextInputProps)=> p.textColor ?? "white"};
    font-size: ${(p: TextInputProps)=>p.fontSize};
    text-align: center;
    margin: 10px;
    :focus {
        border-bottom: 2px solid
            ${(p: TextInputProps) => p.accentColor ?? "white"};
    }
`;

const TextInput = (props: TextInputProps) => {
    return (
        <StyledInput
            disabled={props.disabled}
            onChange={(e) => {
                props.onEdit && props.onEdit(e.target.value);
                props.onChange && props.onChange(e);

            }}
            placeholder={props.placeholder}
            width={props.width}
            height={props.height}
            readOnly={props.readonly}
            defaultValue={props.default}
            maxHeight={props.maxHeight}
            maxWidth={props.maxWidth}
            mask={props.inputMask ?? [/(.+)/] }
            maskPlaceholder={props.maskPlaceholder ? props.maskPlaceholder : ""}
            accentColor={props.accentColor}
            textColor={props.textColor}
            fontSize={props.fontSize}
        />
    );
};

export default TextInput;
