import { reducer } from "../../Store";
import Actions from "../Actions";
import { Card } from '../../DataStructures/Card'

export const fetchCardInfo: reducer = (state, action) => {
    switch (action.type) {
        case Actions.fetchCardInfo: {
            const s = state;
            s.currentUser = { loading: true }
            return s
        }
        case Actions.fetchCardInfoComplete: {
            const card = action.payload as Card
            const s = state
            s.currentUser = {
                id: `${card.id}`,
                name: card.name,
                valid: card.valid,
                loading: false
            }
            return s
        }
    }
}