import React from 'react'
import UserInfo from './Components/UserInfo'
import Store, { useDispatch } from '../../Store'

// import {useParams} from 'react-router-dom'
import qs from 'qs'
import Logo from '../../Components/Logo'
import Flex from '../../Layout/Flex'
import IdInput from '../../Components/IdInput'
import Button from '../../Layout/Button'
import {useCardInfo} from '../../api/cardInfo'
import { redirectToId } from '../../Util/urlUtil'
import { removeSpaces, validateIdText } from '../../Util/stringUtil'

import { Card } from '../../DataStructures/Card'

interface urlParams {
    id: string
}

const Confirmation = () => {
    const params = qs.parse(window.location.search.slice(1))
    const queryID = params.id as string

    // const [cardInfo, setCardInfo] = React.useState<Card>({valid: false})
    // const [loading, setLoading] = React.useState(false)
    
    const [typedText, setTypedText] = React.useState(queryID ?? '')

    const normalizedText = removeSpaces(typedText)

    const [cardInfo, loading] = useCardInfo(normalizedText)
    const {id, name, valid} = cardInfo

    React.useEffect(()=>{
        redirectToId(normalizedText)
    }, [normalizedText])

    const showUserInfo = id && name && valid !== undefined && !loading
    console.log({showUserInfo, id, name, valid, loading})

    return (
        <Flex flexDirection="column" justifyContent="center" alignItems="center" height="100%">
            <Logo style={{
                maxWidth: '100%',
                width: '300px',
                display: 'block',
            }} />
            <div>
                <IdInput default={typedText} onChange={e => setTypedText(e.target.value)} />

            </div>
            {loading && validateIdText(typedText) && "Loading..."}
            {!loading && validateIdText(typedText) && <UserInfo name={name ?? 'Unknown User'} id={normalizedText} approved={!!valid} />}
        </Flex>
    )
}

export default Confirmation