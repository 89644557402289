import React from 'react'

type requestReturnOptional<T> = [T | undefined, boolean]
export type requestReturn<T> = [T, boolean]

export const useRequest = <T>(url: string, cors: boolean = true, def?: T): requestReturnOptional<T> => {
    const [loading, setLoading] = React.useState(false);
    const [data, setData] = React.useState<T | undefined>(def);

    React.useEffect(()=>{
        setLoading(true)
        fetch(url, cors ? { mode: 'cors'} : undefined).then(async data=>{
            return data.json()
        }).then(json=>{
            setLoading(false)
            setData(json)
        }).catch(()=>{
            setLoading(false)
            setData(undefined)
        })
    }, [url, cors])
    return [data, loading]
}