import React from 'react'
import styled from 'styled-components'
import * as flexDefs from './Defs'


interface FlexProps {
    flexDirection?: flexDefs.flexDirection,
    flexWrap?: flexDefs.flexWrap,
    justifyContent?: flexDefs.justifyContent,
    alignItems?: flexDefs.alignItems,
    alignContent?: flexDefs.alignContent,
    width?: string | number,
    height?: string | number
}
export const defs = flexDefs
export default styled.div`
    display: flex;
    flex-direction: ${(p:FlexProps)=>p.flexDirection ?? flexDefs.flexDirection.row};
    flex-wrap: ${(p:FlexProps)=>p.flexWrap ?? flexDefs.flexWrap.none};
    justify-content: ${(p:FlexProps)=>p.justifyContent ?? flexDefs.justifyContent.start};
    align-items: ${(p:FlexProps)=>p.alignItems ?? flexDefs.alignItems.stretch};
    align-content: ${(p:FlexProps)=>p.alignContent ?? flexDefs.alignContent.stretch};
    width: ${(p:FlexProps)=>p.width};
    height: ${(p:FlexProps)=>p.height};
`