

export const flexDirection = {
    row: 'row',
    column: 'column',
    rowReverse: 'row-reverse',
    columnReverse: 'column-reverse'
} as const

export type flexDirection = (typeof flexDirection)[keyof typeof flexDirection]

export const flexWrap = {
    none: 'nowrap',
    wrap: 'wrap',
    reverse: 'wrap-reverse'
} as const

export type flexWrap = (typeof flexWrap)[keyof typeof flexWrap]

export const justifyContent = {
    start: 'flex-start',
    end: 'flex-end',
    center: 'center',
    spaceBetween: 'space-between',
    spaceAround: 'space-around'
} as const

export type justifyContent = (typeof justifyContent)[keyof typeof justifyContent]

export const alignItems = {
    start: 'flex-start',
    end: 'flex-end',
    center: 'center',
    baseline: 'baseline',
    stretch: 'stretch'
} as const

export type alignItems = (typeof alignItems)[keyof typeof alignItems]

export const alignContent = {
    start: 'flex-start',
    end: 'flex-end',
    center: 'center',
    spaceBetween: 'space-between',
    spaceAround: 'space-around',
    stretch: 'stretch'
} as const

export type alignContent = (typeof alignContent)[keyof typeof alignContent]