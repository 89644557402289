import logo from "./logo.svg";
import React from "react";
import Home from "./Pages/Home";
import Confirmation from "./Pages/Confirmation";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { Provider } from "./Store";

function App() {
    return (
        <div
            style={{
                backgroundColor: "#212121",
                width: "100vw",
                height: "100vh",
            }}
        >
            <Provider>
                {/* <Router> */}
                    {/* <Switch> */}
                        {/* <Route path="/id/:id"> */}
                            <Confirmation />
                        {/* </Route> */}
                        {/* <Route path="/">
                            <Home />
                        </Route> */}
                    {/* </Switch> */}
                {/* </Router> */}
            </Provider>
        </div>
    );
}

export default App;
