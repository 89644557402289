

export const formatCardNumber = (cnum: string) => {
    if (!cnum) return ''
    if (cnum.length < 5) return cnum;
    if (cnum.length < 11) return cnum.slice(0, 4) + ' ' + cnum.slice(4)
    return cnum.slice(0, 4) + ' ' + cnum.slice(4, 9) + ' ' + cnum.slice(9)
};

export const insertString = (
    source: string,
    destination: string,
    index: number
): string => {
    return [destination.slice(0, index), source, destination.slice(index)].join(
        ""
    );
};

export const removeSpaces = (text: string) => text.replaceAll(' ', '')

export const validateIdText = (text: string) => {
    const t = removeSpaces(text)
    return t.length >= 13 && /^\d+$/.test(t)
}