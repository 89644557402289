import { Card } from '../DataStructures/Card'
import { useRequest, requestReturn } from './makeRequest'
import delayRepeat from './delayRepeat'

const urlroot = 'https://us-central1-parpass-f588e.cloudfunctions.net/'//purchaseMade?id=0000000000000

export const getCardInfo = async (id: string) => {
    const data = await fetch(urlroot + `queryID?id=${id}`, { mode: 'cors' })
    const json = await data.json()
    const cardInfo = json
    return cardInfo as Card
}

export const useCardInfo = (id: string): requestReturn<Card> => {
    const [card, loading] = useRequest<Card>(urlroot + `queryID?id=${id}`, true)
    if (card) return [card, loading]
    else return [{valid: false}, loading]
}